import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHRBackend from 'i18next-xhr-backend'

i18n.use(initReactI18next)
	.use(XHRBackend)
	.init({
		backend: { loadPath: '/locales/{{lng}}.json' },
		lng: 'en-HK',
		fallbackLng: ['en-HK', 'zh-HK'],
		debug: true,
		load: 'all',
		interpolation: {
			escapeValue: false
		}
	})

export default i18n
