import * as R from 'ramda'
const {
	REACT_APP_ENV,
	REACT_APP_LOCAL_API_URL,
	REACT_APP_SG_API_URL,
	REACT_APP_BR_API_URL
} = process.env

const regionApiMapping = {
	default: REACT_APP_SG_API_URL,
	sg: REACT_APP_SG_API_URL,
	br: REACT_APP_BR_API_URL
}

const getRegionBaseApi = (region: string) => {
	if (REACT_APP_ENV === 'local') {
		return REACT_APP_LOCAL_API_URL
	}
	return R.propOr(
		regionApiMapping.default,
		R.toLower(region),
		regionApiMapping
	)
}

export default getRegionBaseApi
