import styled from 'styled-components'
import { useEffect, useState } from 'react'
import ContactCard from '../components/ContactCard'
import OrderInfo from '../components/OrderInfo'
import Header from '../components/Header'
import LoadingSpinner from '../components/LoadingSpinner'
import NotFound from '../components/error/NotFound'
import TrackingList from '../modules/TrackingList'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { FormatTrackingData } from '../utils/Formatter'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TrackingDataType } from '../types'
import getRegionBaseApi from '../utils/regionApi'
import { v4 as uuidV4 } from 'uuid'

const Wrapper = styled.div`
	max-width: 480px;
	width: 100%;
	background-color: #fff;
	margin: auto;
`
const MainContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
	padding: 8px 16px 28px;
	box-shadow: 0 2px 2px 0 #ccc;
`

const queryClient = new QueryClient()

const Tracking = () => {
	const [locations, setLocations] = useState([])
	const queryParams = new URLSearchParams(useLocation().search)
	const secret = queryParams.get('s')
	const region = queryParams.get('r') ?? 'sg'
	useEffect(() => {
		const fetchLocations = async () => {
			const result = await fetch(
				`${getRegionBaseApi(region)}/locations?limit=100`,
				{
					headers: {
						'X-Request-Id': uuidV4()
					}
				}
			).then(res => res.json())
			setLocations(result)
		}
		fetchLocations()
	}, [])
	const { i18n } = useTranslation()
	const { orderId }: { orderId?: string } = useParams()

	const PageContent = () => {
		if (!secret) {
			return <NotFound />
		}
		const { isLoading, error, data } = useQuery<TrackingDataType>(
			'repoData',
			() =>
				fetch(
					`${getRegionBaseApi(
						region
					)}/orders/${orderId}/tracking?s=${secret}`,
					{
						headers: {
							'X-Request-Id': uuidV4()
						}
					}
				).then(res => res.json())
		)
		if (isLoading) {
			return <LoadingSpinner />
		}

		if (error) {
			return <NotFound />
		}

		const { deliveries, orderInfo, contactInfo } = FormatTrackingData(
			data as TrackingDataType,
			i18n.language,
			locations
		)

		return (
			<>
				<TrackingList
					deliveries={deliveries}
					isCancelled={orderInfo.isCancelled}
				/>
				<OrderInfo orderInfo={orderInfo} />
				<ContactCard contactInfo={contactInfo} />
			</>
		)
	}
	return (
		<Wrapper>
			<Header />
			<QueryClientProvider client={queryClient}>
				<MainContainer>
					<PageContent />
				</MainContainer>
			</QueryClientProvider>
		</Wrapper>
	)
}

export default Tracking
