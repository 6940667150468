import React from 'react'
import styled from 'styled-components'

import Card from './Card'
// @ts-ignore
import { Icon } from '@lalamove/karang'

export interface ContactCardDataProps {
	title?: string
	content?: string
	phoneNumber?: string
}
interface Props {
	contactInfo: ContactCardDataProps
}
const StyledCard = styled(Card)`
	display: flex;
	justify-content: space-between;
`
const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #303030;
	line-height: 1.4;
`
const Content = styled.div`
	font-size: 12px;
	color: #333;
	line-height: 1.2;
`
const BtnPhoneCall = styled.a`
	display: flex;
	align-items: center;
	padding: 0 8px;
`

const IconPhoneCall = styled(Icon)`
	transform: scale(1.2);
	background-color: #f16622;
	padding: 8px;
	border-radius: 100%;
`

const ContactCard = (props: Props) => {
	const { contactInfo } = props
	const { title, content = '', phoneNumber } = contactInfo
	return (
		<StyledCard>
			<div>
				<Title>{title}</Title>
				<Content dangerouslySetInnerHTML={{ __html: content }} />
			</div>
			{phoneNumber && (
				<BtnPhoneCall href={`tel:${phoneNumber}`}>
					<IconPhoneCall type="tel" color={'#FFF'} />
				</BtnPhoneCall>
			)}
		</StyledCard>
	)
}

export default React.memo(ContactCard)
