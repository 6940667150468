import React, { useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { Dropdown } from '@lalamove/karang'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 48px;
	background-color: #fff;
	box-shadow: 0px 2px 2px 0px #ccc;
	justify-content: space-between;
	align-items: center;
`
const LogoContainer = styled.a`
	width: 88px;
	height: 24px;
	margin: 12px 20px;
	display: block;
`

const LangDropdown = styled(Dropdown)`
	button {
		border: none;
	}
`

const Header = () => {
	const { t, i18n } = useTranslation()
	const [lang, setLang] = useState({
		selected: {
			value: 'en-HK',
			label: t('language.en-HK')
		}
	})
	const langDropdownItems = [
		{
			value: 'en-HK',
			label: t('language.en-HK')
		},
		{
			value: 'zh-HK',
			label: t('language.zh-HK')
		}
	]

	const changeLanguage = (languageItem: { value: string; label: string }) => {
		setLang({ selected: languageItem })
		i18n.changeLanguage(languageItem.value)
	}
	return (
		<Wrapper>
			<LogoContainer href="https://www.lalamove.com/">
				<img
					src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
					alt="logo"
				/>
			</LogoContainer>
			<LangDropdown
				selectedItem={lang.selected}
				items={langDropdownItems}
				onChange={(item: { value: string; label: string }) =>
					changeLanguage(item)
				}
			/>
		</Wrapper>
	)
}

export default React.memo(Header)
