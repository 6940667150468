import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import { Card } from '@lalamove/karang'

const StyledCard = styled(Card)`
	padding: 12px;
	width: 100%;
	box-shadow: 0 2px 4px 0 #ccc;
	position: relative;
`

export default React.memo(StyledCard)
