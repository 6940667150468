import dayjs from '../utils/DateTimeUtil'
import * as R from 'ramda'

import type { ContactCardDataProps } from '../components/ContactCard'
import type { CollapseCardItemProps } from '../components/DeliveryCard'
import type { LogProps } from '../components/LogInfo'
import type { OrderInfoDataProps } from '../components/OrderInfo'
import type { DeliveryType, LocaleType, TrackingDataType } from '../types'

type LocationType = {
	id: string
	timezone: string
}
type TimezoneType = string | undefined

const dateFormat = 'YYYY-MM-DD HH:mm ([UTC]Z)'

const getTimezone = (
	locationId: string,
	locationList: LocationType[]
): TimezoneType => {
	const formattedLocations = R.groupBy(
		(location: { id: string }) => location.id,
		locationList
	)
	return R.path([locationId, 0, 'timezone'], formattedLocations)
}
const day = (date: string, timezone?: TimezoneType) => {
	return timezone
		? dayjs.utc(date).tz(timezone).format(dateFormat)
		: dayjs(date).format(dateFormat)
}
const getObjectLocaleValue = (data: LocaleType[], locale: string): string => {
	const result = R.filter(item => item.locale === locale, data)
	return R.pathOr('', [0, 'value'], result)
}

const sortDeliveriesByCreatedAt = (
	delivery1: DeliveryType,
	delivery2: DeliveryType
) =>
	dayjs.utc(delivery1.createdAt).isAfter(dayjs.utc(delivery2.createdAt))
		? 1
		: -1

const sortLogsByCreatedAt = (log1: LogProps, log2: LogProps) =>
	dayjs(log1.createdAt).isAfter(dayjs(log2.createdAt)) ? -1 : 1

const populateDeliveries = (
	deliveryList: DeliveryType[],
	locationId: string,
	locations: LocationType[]
) => {
	const sortedDeliveries = R.sort(sortDeliveriesByCreatedAt, deliveryList)
	const { parcelRef, status, statusBarStep, updatedAt: initialUpdatedAt } =
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- can be sure that at least one delivery here
		R.last(sortedDeliveries)!
	const formSubmissions = R.compose(
		R.uniqBy((fs: DeliveryType['formSubmissions'][number]) => fs.id),
		R.flatten,
		R.map((delivery: DeliveryType) => delivery.formSubmissions)
	)(deliveryList)
	const logs = formSubmissions.map(
		({
			createdAt: formSubmissionCreatedAt,
			tag,
			locationId: fsLocationId
		}) => ({
			createdAt: day(
				formSubmissionCreatedAt,
				getTimezone(fsLocationId, locations)
			),
			status: tag
		})
	)
	const earliestDelivery = R.head(sortedDeliveries)
	if (earliestDelivery) {
		logs.push({
			createdAt: day(
				earliestDelivery.createdAt,
				getTimezone(locationId, locations)
			),
			status: 'DELIVERY_REQUESTED'
		})
	}
	const sortedLogs = R.sort(sortLogsByCreatedAt, logs)
	const updatedAt = R.head(sortedLogs)?.createdAt || initialUpdatedAt
	return {
		deliveryId: parcelRef,
		status,
		statusBarStep,
		updatedAt,
		logs: sortedLogs
	} as CollapseCardItemProps
}

export const FormatTrackingData = (
	{
		deliveries = [],
		updatedAt: deliveryUpdatedAt,
		orderRef = '',
		status: orderStatus,
		trackingName = [],
		trackingFooterTitle = [],
		trackingFooterContent = [],
		trackingContact = '',
		locationId,
		isCancelled
	}: TrackingDataType,
	locale: string,
	locations: LocationType[]
) => {
	const transformedDeliveries = R.compose(
		R.map((delivery: DeliveryType[]) =>
			populateDeliveries(delivery, locationId, locations)
		),
		R.values,
		R.groupBy<DeliveryType>(delivery => delivery.parcelRef)
	)(deliveries)

	const clientForm = getObjectLocaleValue(trackingName, locale)
	const orderInfo: OrderInfoDataProps = {
		form: clientForm,
		orderRef,
		orderStatus,
		updatedAt: day(deliveryUpdatedAt, getTimezone(locationId, locations)),
		isCancelled
	}

	const contactTitle = getObjectLocaleValue(trackingFooterTitle, locale)

	const contactMain = getObjectLocaleValue(trackingFooterContent, locale)

	const contactInfo: ContactCardDataProps = {
		title: contactTitle,
		content: contactMain,
		phoneNumber: trackingContact
	}

	return { deliveries: transformedDeliveries, orderInfo, contactInfo }
}
