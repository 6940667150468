import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ErrorContainer = styled.div`
	padding: 48px 0;
	text-align: center;
`
const Title = styled.div`
	font-size: 18px;
`
const Content = styled.div`
	font-size: 14px;
`

const NotFound = () => {
	const { t } = useTranslation()
	return (
		<ErrorContainer>
			<img src={`${process.env.PUBLIC_URL}/assets/error/404.svg`} />
			<Title>{t('error.notFound.title')}</Title>
			<Content>{t('error.notFound.content')}</Content>
		</ErrorContainer>
	)
}

export default NotFound
