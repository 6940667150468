import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

export interface LogProps {
	createdAt: string
	status: string
}
interface WrapperProps {
	active: boolean
}

type Props = {
	logs: LogProps[]
} & WrapperProps

const Wrapper = styled.div<WrapperProps>`
	margin-top: 8px;
	overflow: hidden;
	max-height: auto;
	${props =>
		!props.active &&
		css`
			max-height: 0;
		`};
` as React.FunctionComponent<WrapperProps>

const LogItem = styled.div`
	display: flex;
	padding: 12px 0;
	gap: 12px;
	color: #888;
	font-size: 12px;
	&:last-child {
		padding-bottom: 0;
	}
	&:not(:last-child) {
		border-bottom: 1px solid #e0e0e0;
	}
`
const CreatedTime = styled.div`
	display: flex;
	white-space: nowrap;
	align-items: center;
`
const LogInfo = (props: Props) => {
	const { active, logs } = props
	const { t } = useTranslation()
	return (
		<Wrapper active={active}>
			{Array.isArray(logs) &&
				logs.map((log, i) => (
					<LogItem key={`log-${i}`}>
						<CreatedTime>{log.createdAt}</CreatedTime>
						<div>{t(`logStatus.${log.status}`)}</div>
					</LogItem>
				))}
		</Wrapper>
	)
}

export default React.memo(LogInfo)
