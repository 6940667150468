// @ts-ignore
import { Spinner } from '@lalamove/karang'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
	padding: 48px 0;
	margin: auto;
`

const LoadingSpinner = () => (
	<SpinnerContainer>
		<Spinner size="large" />
	</SpinnerContainer>
)

export default LoadingSpinner
