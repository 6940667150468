import styled from 'styled-components'

import CollapseCard, { CollapseCardItemProps } from '../components/DeliveryCard'

interface Props {
	deliveries: CollapseCardItemProps[]
	isCancelled?: boolean
}
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const TrackingList = (props: Props) => {
	const {
		deliveries,
		isCancelled
	}: { deliveries: CollapseCardItemProps[]; isCancelled?: boolean } = props
	return (
		<Wrapper>
			{deliveries.map((delivery: CollapseCardItemProps, i: number) => {
				return (
					<CollapseCard
						delivery={delivery}
						key={`delivery-${i}`}
						isCancelled={isCancelled}
					/>
				)
			})}
		</Wrapper>
	)
}

export default TrackingList
